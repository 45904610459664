@keyframes rolling {
    0% {
        transform: perspective(300px) rotate3d(0, 1, 1, 0deg);
    }
    50% {
        transform: perspective(300px) rotate3d(0, 1, 1, 180deg);
    }
    100% {
        transform: perspective(300px) rotate3d(0, 1, 1, 360deg);
    }
}

@keyframes flipping {
    0% {
        transform: perspective(300px) rotate3d(0, 1, 0, 0deg);
    }
    50% {
        transform: perspective(300px) rotate3d(0, 1, 0, 180deg);
    }
    100% {
        transform: perspective(300px) rotate3d(0, 1, 0, 360deg);
    }
}

@keyframes rolling-0 {
    100% {
        transform: perspective(300px) rotate3d(0, 0, 1, 90deg);
    }
}

@keyframes rolling-1 {
    100% {
        transform: perspective(300px) rotate3d(0, 1, 0, 90deg);
    }
}

@keyframes rolling-2 {
    100% {
        transform: perspective(300px) rotate3d(0, 1, 0, 180deg);
    }
}

@keyframes rolling-3 {
    100% {
        transform: perspective(300px) rotate3d(0, 1, 0, 270deg);
    }
}

@keyframes rolling-4 {
    100% {
        transform: perspective(300px) rotate3d(1, 0, 0, 270deg);
    }
}

@keyframes rolling-5 {
    100% {
        transform: perspective(300px) rotate3d(1, 0, 0, 90deg);
    }
}
